<template>
  <div class="auth-device-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
let validDeviceSN = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //仅支持输入大小写字母、数字，限制1~32位字符
    let reg = /^[A-z0-9]{1,32}$/;
    if (!reg.test(value)) {
      callback(new Error("SN地址格式不对"));
    } else {
      callback();
    }
  }
};
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "人证机列表",
        search: [
          {
            label: "省份",
            key: "province",
            type: "selectCity",
            opt: {
              list: [],
              change(data) {
                _this.getCityList(data);
              }
            }
          },
          {
            label: "城市",
            key: "city",
            type: "selectCity",
            opt: {
              list: [],
              parent: "province",
              change(data) {
                _this.getApartmentList(data);
              }
            }
          },
          {
            key: "apartmentId",
            label: "房源名称",
            type: "select",
            opt: {
              list: [],
              parent: "city"
            }
          },
          {
            key: "deviceSN",
            label: "SN地址",
            maxlength: 32,
              rules: [{
                validator: validDeviceSN,
                trigger: ['blur']
              }]
          }
        ],
        columns: [
          {
            label: "设备SN",
            key: "deviceSN",
            on: row => {
              _this.$router.push("/main/auth-device/detail/show/" + row.rzjId);
            }
          },
          { label: "安装位置", key: "area" },
          { label: "房源名称", key: "apartmentName" },
          // { label: "楼栋", key: "buildings"},
          // { label: "单元", key: "units"},
          { label: "状态", key: "stateStr" },
          {
            label: "操作",
            key: "rzjId",
            type: "action",
            opt: {
              list: [
                {
                  label: "记录",
                  on(row) {
                    _this.$router.push({
                      path: "/main/guard/list?rzjId=" + row.rzjId
                    });
                  }
                }
              ]
            }
          }
        ],
        buttons: [
          {
            type: 0,
            on() {
              _this.$router.push("/main/auth-device/detail/add/0");
            }
          },
          {
            type: 1,
            on(data) {
              _this.$router.push(
                "/main/auth-device/detail/edit/" +
                  data.data.rzjId +
                  "?apartmentId=" +
                  data.data.apartmentId
              );
            }
          },
          {
            type: 2,
            on(data) {
              _this
                .$confirm("此操作将删除选择项, 是否继续?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                })
                .then(() => {
                  var dto = {
                    deviceId: data.list[0].rzjId,
                    apartmentId: data.list[0].apartmentId
                  };
                  _this
                    .post("/device-service/rzjmanagment/deleteRzjDevice", dto, {
                      isUseResponse: true
                    })
                    .then(function(res) {
                      _this.$message({
                        message: "删除成功",
                        type: "success"
                      });
                      data.refresh();
                    })
                    .catch(function(res) {
                      _this.$message({
                        message: res.data.msg,
                        type: "warning"
                      });
                    });
                })
                .catch(function() {});
            }
          }
        ]
      }
    };
  },
  created() {
    console.log("authDevice-list created!!");
    this.onclickProvince();
  },
  activated() {
    this.onclickProvince();
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        isWeb:true,
        ...opt.searchForm
      };
      this.post("device-service/rzjmanagment/rzj-List", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.stateStr = ["禁用", "正常"][item.state];
        }
        opt.cb(res.data);
      });
    },
    //获取房源列表
    getApartmentList(cityId) {
      var dto = {
        pageNumber: "1",
        pageSize: "99999",
        city: cityId
      };
      return new Promise((resolve, reject) => {
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          res => {
            for (let i = 0; i < this.opt.search.length; i++) {
              let data = this.opt.search[i];
              if (data.key === "apartmentId") {
                res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                data.opt.list = res;
                break;
              }
            }
          }
        );
      });
    },

    onclickProvince() {
      this.post("/landlord-service/base/getProvinces").then(res => {
        for (let i = 0; i < this.opt.search.length; i++) {
          let data = this.opt.search[i];
          if (data.key === "province") {
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            data.opt.list = res;
            break;
          }
        }
      });
    },
    // 获取城市列表
    getCityList(name) {
      this.post("/landlord-service/base/getCitys?name=" + name).then(res => {
        for (let i = 0; i < this.opt.search.length; i++) {
          let data = this.opt.search[i];
          if (data.key === "city") {
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            data.opt.list = res;
            break;
          }
        }
      });
    }
  }
};
</script>